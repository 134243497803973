

var MergeToolIntegration = 
{
    getGeomId1: function()
    {
        return document.getElementById('merge-tool-geom-id1').value;
    },
    getGeom1: function()
    {
        return document.getElementById('merge-tool-geom1').value;
    },
    getGeomId2: function()
    {
        return document.getElementById('merge-tool-geom-id2').value;
    },
    getGeom2: function()
    {
        return document.getElementById('merge-tool-geom2').value;
    },
    getSRS: function()
    {
        return document.getElementById('merge-tool-geom-srs').value;
    },
    getBaseLayerURL: function()
    {
        return document.getElementById('merge-tool-baselayer-url').value;
    },
    getBaseLayerName: function()
    {
        return document.getElementById('merge-tool-baselayer-layername').value;
    },
    setOutGeom: function(geoJSON)
    {
        document.getElementById('merge-tool-out-geom-json').value=geoJSON;
    },
    save: function()
    {
        document.getElementById('merge-tool-submit-form').click();
    }
}
