

var SplitToolIntegration = 
{
    getGeomId: function()
    {
        return document.getElementById('split-tool-geomid').value;
    },
    getGeom: function()
    {
        return document.getElementById('split-tool-geom').value;
    },
    getSRS: function()
    {
        return document.getElementById('split-tool-geomsrs').value;
    },
    getBaseLayerURL: function()
    {
        return document.getElementById('split-tool-baselayer-url').value;
    },
    getBaseLayerName: function()
    {
        return document.getElementById('split-tool-baselayer-layername').value;
    },
    setFeatureId: function(featureId)
    {
        document.getElementById('split-tool-feature-id').value=featureId;
    },
    setGeom1WKT: function(geoJSON)
    {
        document.getElementById('split-tool-geom1-json').value=geoJSON;
    },
    setGeom2WKT: function(geoJSON)
    {
        document.getElementById('split-tool-geom2-json').value=geoJSON;
    },
    saveSplit: function()
    {
        document.getElementById('split-tool-submit-form').click();
    },
    getEdificationsFeaturesJSON: function()
    {
        return document.getElementById('split-tool-edifications-features-json').value;
    },
    setRemovedEdificationFeaturesJSON: function(features)
    {
        document.getElementById('split-tool-removed-edifications-features-json').value=features;        
    },
    setNewEdificationFeaturesJSON: function(features)
    {
        document.getElementById('split-tool-new-edifications-features-json').value=features;        
    }



}
