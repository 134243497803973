var measureControl=null;
var CDR = 0.01745329251994329576;	//!< Conversion factor: degrees to radians
var CRD = 57.29577951308232087679;	//!< Conversion factor: radians to degrees
var PI = 3.141592653589793
var PI_TIMES_2 = 2.0 * PI;
var measureResultLayers = [];
var measureLabelsLayers = [];



function toogleMeasureControl(enable, map)
{
    proj4.defs("EPSG:31983","+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

    if(enable)
    {
        var options = {
            units: {},
            position: 'topleft',
            primaryLengthUnit: 'meters',
            secondaryLengthUnit: 'kilometers',
            primaryAreaUnit: 'sqmeters',
            activeColor: '#ABE67E', // base color for map features while actively measuring
            completedColor: '#C8F2BE', // base color for permenant features generated from completed measure
            captureZIndex: 10000, // z-index of the marker used to capture measure events
            popupOptions: {
              className: 'leaflet-measure-resultpopup',
              autoPanPadding: [10, 10]
            }
          }
        measureControl = new L.Control.Measure(options);
        measureControl.addTo(map);

        // measureControl.showCoordinates = function(coords)
        // {
        //     for (let i = 0; i < coords.length; i++) {
        //         const coord = coords[i];
        //         console.log(coord);                
        //     }
        // }

        map.on('layerremove', function(e)
        {
            if(e.layer.options.className=="layer-measure-resultarea"
            || e.layer.options.className=="layer-measure-resultline"
            || e.layer.options.className=="layer-measure-resultpoint")
            {
                measureLabelsLayers.forEach(labelLayer => 
                {
                    measureControl._layer.removeLayer(labelLayer);
                });
                measureLabelsLayers = [];
            }
        })

        map.on('layeradd', function(e)
        {
            if(e.layer.options.className=="layer-measure-resultarea"
            || e.layer.options.className=="layer-measure-resultline"
            || e.layer.options.className=="layer-measure-resultpoint")
            {
                measureResultLayers.push(e.layer);

            } else if(e.layer.options.className=="measure-labels")
            {
                measureLabelsLayers.push(e.layer);
            }
        });
        
        map.on('cancelmeasure', function() {
            measureLabelsLayers.forEach(labelLayer => 
                {
                    measureControl._layer.removeLayer(labelLayer);
                });
                measureLabelsLayers = [];
        });

        map.on('measurefinish', function(model) {

            let coordinates=[];
            let segments=[];
            for (let i = 0; i < model.points.length; i++) 
            {
                coordinates[i] = new jsts.geom.Coordinate(model.points[i].lng, model.points[i].lat);
            }
         
            for (let i = 0; i < coordinates.length; i++) 
            {
                var segment
                if(i==0)
                {
                    segment = new jsts.geom.LineSegment(coordinates[coordinates.length-1], coordinates[i]);
                }
                else
                {
                    segment = new jsts.geom.LineSegment(coordinates[i-1], coordinates[i])
                }

                segments[segments.length] = segment;
            }

            if(segments.length>1)
            {
                showAngleForSegments(measureControl, segments);
            }
            showDistanceForSegments(measureControl, segments);
            
        });


        measureControl._layer.on('add', function(e)
        {
            console.log(e);
        });

        //Remove last vertex with ESC
        document.addEventListener("keydown", function(event) {
        const key = event.key;
        if (key === "Escape") 
        {
            if(measureControl)        
            {
                measureControl.removeLastVertex();
            }        
        }
  });


    }
    else
    {
        if(measureControl)
        {
            map.removeControl(measureControl);
        }
    }

    return measureControl;

}

function showDistanceForSegments(map, segments)
{
    for (let i = 0; i < segments.length; i++) 
    {        
        var origin = L.latLng(segments[i].p0.y,segments[i].p0.x);
        var destiny = L.latLng(segments[i].p1.y,segments[i].p1.x);
        
        var distanceInMeters = origin.distanceTo(destiny);

        var distanceText;

        if(distanceInMeters>2000)
        {
            distanceText = (distanceInMeters/1000).toFixed(2) + "km";
        }
        else
        {
            distanceText = distanceInMeters.toFixed(2) + "m";
        }

        var distanceStyleClassName = 'measure-labels';

        var centroid = segments[i].midPoint();        

        showTextOverMap(measureControl, centroid.x, centroid.y, distanceText, distanceStyleClassName);


    }
}

function showAngleForSegments(measureControl, segments)
{

    for (let i = 0; i < segments.length; i++) 
    {

        var segment1;

        if(i!=0)
        {
            segment1 = segments[i-1];
        }
        else
        {
            segment1 = segments[segments.length-1];
        }
        
        var segment2 = segments[i]; 
        var tail;
        var tip1;
        var tip2;

        if(segment1.p0==segment2.p0)
        {
            tail = segment1.p0;
            tip1 = segment1.p1;
            tip2 = segment2.p1;
        } else if(segment1.p0==segment2.p1)
        {
            tail = segment1.p0;
            tip1 = segment1.p1;
            tip2 = segment2.p0;

        } else if(segment1.p1==segment2.p0)
        {
            tail = segment1.p1;
            tip1 = segment1.p0;
            tip2 = segment2.p1;
        } else if(segment1.p1==segment2.p1)
        {
            tail = segment1.p1;
            tip1 = segment1.p0;
            tip2 = segment2.p0;
        }

        
        var newAngle = angleBetweenOriented(tip1, tail, tip2);
        var angleText = "#" + (i+1) + "\n " + parseInt(toDegrees(newAngle)) + "°";
        var angleStyleClassName = 'measure-labels';

        showTextOverMap(measureControl, tail.x, tail.y, angleText, angleStyleClassName);
    }
   

}

function showTextOverMap(measureControl, y, x, text, className)
{
    var textLatLng = [x ,y];  

    var myTextLabel = L.marker(textLatLng, {
        icon: L.divIcon({
            className: className,   
            html: text
        }),
        zIndexOffset: 1000,
        className: className
    });

    measureLabelsLayers.push(myTextLabel);

    measureControl._layer.addLayer(myTextLabel)

    return myTextLabel;
}

function angleBetweenOriented(tip1, tail, tip2) 
{
    var a1 = angle(tail, tip1);
    var a2 = angle(tail, tip2);
    var angDel = a2 - a1;
    
    if (angDel <= -PI)
        return angDel + PI_TIMES_2;
    if (angDel > PI)
        return angDel - PI_TIMES_2;
    return angDel;
}

function angle(p0, p1) 
{
    var dx = p1.x - p0.x;
    var dy = p1.y - p0.y;
    return Math.atan2(dy, dx);
}

function toDegrees(radians) {
    return Math.abs((radians * 180) / (PI));
}

function copyToClipboard(id) 
{
  var copyText = document.getElementById(id);
  if(copyText)
  {
    copyText.type = 'text';
    copyText.style = 'display: none; width: 1px;';
    copyText.focus();
    navigator.clipboard.writeText(copyText.innerHTML);
    console.log('Coord Copied to clipboard: ' + copyText.innerHTML);
  }  
}